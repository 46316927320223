<template>
  <div class="d-flex align-items-center justify-content-between w-100">
    <div class="title">
      {{ title }}
      <span v-if="withSubtitleAction" class="subtitle clickable-item-hov" @click="subtitleActionClicked">{{
        subtitleActionLabel
      }}</span>
    </div>
    <ion-button v-if="withAction" class="edit-btn" fill="clear" color="dark" @click="actionClicked">
      {{ actionLabel }}
    </ion-button>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

@Options({
  name: 'TitleWithAction',
})
export default class TitleWithAction extends Vue {
  @Prop() public title!: string;
  @Prop() public actionLabel!: string;
  @Prop() public withAction!: boolean;
  @Prop({ default: '' }) public subtitleActionLabel!: string;
  @Prop({ default: false }) public withSubtitleAction!: boolean;

  @Emit()
  public actionClicked() {
    
  }

  @Emit()
  public subtitleActionClicked() {
    
  }
}
</script>

<style lang="sass" scoped>
.title
  font-size: 20px
  font-weight: 700
.edit-btn
  text-transform: unset
  font-size: 12px
  user-select: none !important
  --padding-end: 5px
  --padding-start: 5px
  height: 20px
.subtitle
  font-size: 13px
  font-weight: normal
  color: purple

@media(max-width: 520px)
  .title
    display: flex
    flex-direction: column
</style>
